<template>
  <div class="content-section">

    <div class="highlights-header">
      <h2 class="section-title">{{ title }}</h2>
      <div class="section-description subtitle">
        <p>{{ description }}</p>
<!--        <router-link to="/all-news" class="view-all" style="color: #409eff; text-decoration: none;">-->
<!--          查看更多 >-->
<!--        </router-link>-->
      </div>
    </div>

    <el-row :gutter="20" class="flow-box">
      <el-col
          v-for="(item, index) in items"
          :key="index"
          :xs="24"
          :sm="12"
          :md="8"
          :lg="8"
          :xl="8"
      >
<!--      <el-col :span="8" v-for="(item, index) in items" :key="index">-->
        <el-card class="fixed-size-card">
          <div class="card-content">
            <span class="title">{{ item.title }}</span>
            <span class="date-time">{{ item.date_time }}</span>
            <p class ="paper" v-html="item.description"></p>
          </div>
        </el-card>

      </el-col>
    </el-row>
  </div>

</template>

<script>
// import axios from "axios";
import {ElCard, ElCol, ElRow} from 'element-plus'
export default {
  name: 'ContentSection',
  components: { ElCard, ElRow, ElCol },
  data() {
    return {
      title: '热点叙事',
      description: '加密经济永远不缺新的叙事，远的有早几年的Defi Summer、加密猫、GameFi、平台币、新Layer1、DePIN、ZKRollup等等，近的有23年的比特币铭文，' +
          '24年至今加密经济虽然缺少跟去年比特币铭文相提并论的新叙事逻辑，但也还围绕着"BTC减半"、"现货ETF上市"、"降息"、"各种诉讼赔偿结案"等主旋律，出现了诸如老币换发新春（SOL）、' +
          'Layer1新王有望引领新一轮牛市等热点或舆情，ZKware LAB持续跟踪相关赛道的投资机会，并无偿分享给社区。',
      items: [
        {
          title: "VC币和meme币互不接盘？",
          description: 
          `随着BTC2024年11月开始连续突破9.5万、10万、10.5万的高点，第四次比特币减半行情已经是中局了。回顾自2024年4月20日正式减半以来，币圈里始终有一个声音，就是你玩你的VC币，我玩我的meme币，小韭菜们热衷于追逐各种meme币，也造就了pnuts等个别1000x+的明星币，同时也让最佳meme币平台SOL和SUI在这一波中异军突起，走出了一波3x+的行情，那些VC币如xrp，ada，fil，aleo等在这波行情早期确实无人问津，然而棋至中局，xrp通过碰瓷sec、发行稳定币等各种制造话题吸引流量的手段，也走出了一波4x+的行情，ada最高也有3x的涨幅，fil和dot都有翻倍以上的行情。周围很多朋友也都关注，牛市下半场到底还是追热点meme币还是挖掘潜力VC币？<br>

<b>以下观点分享提及的各种加密币种，zkware可能持有，因此观点带有强烈的仓位偏好，但并不构成投资建议。</b><br>

<br><br><b>1、meme币自始至终不改其高赔率低胜率的属性</b>

<br>meme币的祖师爷doge、shib的造富效应，让人瞠目结舌，押中低位的doge或者shib，确实令人神往，但回看当时的各种仿品狗币、猫币，数量可能以万计，绝大数都已经归于尘土了。今天的meme币，依然还是同样的逻辑，看solana和sui上有多少meme币，而跑出来的不过一只手能数出来的。所以meme币高赔率低胜率的属性，决定了在每个币种上，很难下重注，还是比较适合小资金用买彩票的心态玩一下。

<br><br><b>2、完全放弃meme币也不是一个明智的选择</b>

<br>对于meme的龙头doge，我们还是一直长期重仓持有，也看好明年马斯克走马上任的同名新政府部门带来的流量和话题效应。

<br><br><b>3、VC币跟21年有什么不同</b>

<br>21年的大牛市，那个时候市场的焦点都在看灰度持仓了哪些币，A16Z投了哪个公司，时至今日，看这些头部机构的投资成绩，其实不比小散强了多少，有数据指出，如果不是sol强势崛起，几乎所有VC投的项目都是亏损严重，哪怕在当下的牛市进行时，扣除sol，他们投的项目总市值也没有回到本金水平。所以说买VC币的风险也是非常大的，过去跟着灰度买fil躺赚的时代已经结束了。

<br><br><b>4、新的机会在哪里</b>

<br>棋至中盘，肯定还有一些100x乃至1000x的机会出现，我们的观点是，如其去追逐这些100x+的机会，不如把精力更多的放在主流的btc、eth等龙头上，利用最新的AI技术，开发一些交易工具和策略来对冲风险同时放大收益。

 `,
          date_time: "2024-12-18"
        },
        {
          title: "AI赛道",
          description: "12月OpenAI为期12天的新品发布会注定在投资市场掀起不小的波澜，ZKware LAB 看好AI赛道在12月的表现，核心关注标的有 $WLD $RENDER $LPT $CLANKER  等，AI MEME 与 AI agent 也是潜在爆发机会 ，如 $ACT $GOAT $TURBO 等波动较大，请注意做好风险控制。 ",
          date_time: "2024-12-05"
        },
        { 
          title: "RWA赛道",
          description: "RWA（真实世界资产，Real World Assets）赛道是指将现实世界中的资产（如房地产、商品、债券等）数字化并在区块链上进行交易和管理的领域。这一赛道的核心在于通过区块链技术实现资产的透明化、可追溯性和高效流通。赛道中如$MKR $ONDO $IOTA等均是高潜力标的，值得关注。",
          date_time: "2024-12-05"
        },
        {
          title: "SOL",
          description: "<a href=\"https://solana.com/\" target=\"_blank\">SOL</a>仍是本轮牛市的发动机，链上生态异常繁荣，phantom钱包已经成为新人入场首选，MEME赛道热度独步天下��造富神话自24年起不绝。自24年9月加入榜单以来，最大涨幅超100%+，仍看好其后市表现。",
          date_time: "2024-12-01"
        },
        {
          title: "SUI",
          description: "<a href=\"https://sui.io/\" target=\"_blank\">SUI</a>\n" +
              "      是MOVE生态3大公链之一，目前生态内TVL与市值均位列第一。自24年9月加入榜单以来，最大涨幅超300%+，仍看好其后市表现。SUI在油管上知名频道\n" +
              "      <a href=\"https://www.youtube.com/watch?v=EfNlejjM1Cc\">CoinBureau</a> 、\n" +
              "      <a href=\"https://www.youtube.com/watch?v=gjN8gwtvioQ\">Paul Barron</a>、\n" +
              "      <a href=\"https://www.youtube.com/watch?v=GJOxJWLRWv0\">Think Crypto</a> 、\n" +
              "      <a href=\"https://www.youtube.com/watch?v=rnMzUH7oNk4\">脑哥区块链</a> 、\n" +
              "      <a href=\"https://www.youtube.com/watch?v=6GdQ79WVAAM\">区块链日报</a> 等获得频繁曝光。",
          date_time: "2024-12-01"
        },
        {
          title: "XRP",
          description: "<a href=\"https://xrpl.org/\"  target=\"_blank\">XRP</a>\n" +
              "      在24年12月成功拿下市值榜TOP3，30D涨幅430%+，成为Q4特朗普交易中因加密政策宽松最大赢家。\n" +
              "      ZKware LAB目前正按计划逐步止盈减持，并持续跟踪相关进展，以下通过Chatgpt每日跟踪精选关于XRP的舆情，与社区分享。",
          date_time: "2024-12-01"
        },
        {
          title: "DOGE",
          description: "<a href=\"https://dogecoin.com/\"  target=\"_blank\">DOGE</a>\n" +
              "      MEME赛道市值TOP1，是11月受益于特朗普当选最显著的标的。\n" +
              "      自24年9月加入榜单以来，最大涨幅超300%+，因马斯克主导政府效率部(DOGE)将使其话题度贯穿整个2025，仍看好其后市表现。\n" +
              "      ZKware LAB目前正按计划逐步止盈减持，并持续跟踪相关进展，以下通过Chatgpt每日跟踪精选关于DOGE的舆情，与社区分享。",
          date_time: "2024-12-01"
        },
        {
          title: "ALEO",
          description: "<a href=\"https://aleo.org/\" target=\"_blank\">ALEO</a>\n" +
              "      简单的说来，就是构建了一个零知识证明的开发平台，最近Tornado Cash 官司胜诉的消息给隐私赛道带来新的机遇和希望。\n" +
              "      ZKware LAB从Testnet2开始就深度参与这个项目，并且也重仓持aleo，并持续跟踪相关进展，以下通过Chatgpt每日跟踪精选关于ALEO的舆情，与社区分享。",
          date_time: "2024-12-01"
        },
      ]
    }
  }


}
</script>

<style scoped>
.content-section {
  margin-bottom: 10px;
}
.section-title {
  font-size: 24px;
  margin-bottom: 10px;
}
.section-description {
  color: #666;
  margin-bottom: 20px;
}
.subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #666;
  font-size: 14px;
}
.flow-box {
  display: flow;
}
.fixed-size-card {
  /* height: 400px; */
  display: inline-block;
  margin-bottom: 20px;
  /* flex-direction: column; */
}

.card-content {
  display: inline-block;
  min-height: 200px;
  flex-grow: 1;
  overflow-y: auto;
}

.card-footer {
  padding: 10px;
  border-top: 1px solid #ebeef5;
  font-size: 0.9em;
  color: #606266;
}

.news-item {
  margin-bottom: 10px;
}

.link {
  display: block;
  text-align: left;
  color: #409EFF;
  text-decoration: none;
  font-size: 0.95em;
  line-height: 1.4;
}

.link:hover {
  text-decoration: underline;
}

.date {
  font-size: 0.85em;
  color: #909399;
  margin-left: 5px;
}

.date-time {
  float: right;
  color: #909399;
}
.title {
  font-weight: bold;
}
.paper {
  text-align: left;
}
</style>