<template>
<!--  策略详情页面-->
  <el-card class="volatility-card">
    <el-page-header @back="goBack">
      <template #content>
        <span class="text-large font-600 mr-3">策略实盘表现</span>
      </template>
    </el-page-header>
    <el-tabs class="demo-tabs">
      <el-tab-pane v-for="(chart, index) in charts" :label="chart.title" :key="index">
        <!--        显示静态页面 -->
        <div v-if="chart.showStatic">
          <strategy-static :chart="chart" />
        </div>
        <div v-else>
          <!--        显示动态页面 -->
          <div class="strategy-header">
            <h2>{{ chart.title }}</h2>
          </div>
          <!-- 实盘净值部分 -->
          <div class="section">
            <h3 style="text-align: left;">实盘净值:</h3>
            <div class="chart-container" v-if="chart.data">
              <line-chart :data="chart.data" :options="chartOptions" />
            </div>
          </div>
          <!-- 策略说明部分 -->
          <div class="section">
            <h3 style="text-align: left;">{{ chart.title }}</h3>
            <div class="strategy-desc">
              <el-alert type="info" :closable="false" show-icon>
<!--                {{chart.detail}}-->
                <span v-html="chart.detail"></span>
              </el-alert>
            </div>
          </div>

          <div class="section" v-if="chart.showProfit">
            <h3>预期收益</h3>
            <profit-chart />
          </div>
        </div>
      </el-tab-pane>

    </el-tabs>

  </el-card>
</template>

<script>
import { Line as LineChart } from 'vue-chartjs'
import { zhCN } from 'date-fns/locale';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, Filler } from 'chart.js'
import axios from "axios";
import 'chartjs-adapter-date-fns';
import { ElTabs, ElTabPane, ElPageHeader, ElCard } from 'element-plus'
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, Filler)
import ProfitChart from "@/components/details/ProfitChart.vue";
import StrategyStatic from "@/components/details/StrategyStatic.vue";

export default {
  name: 'AdvancedStrategies',
  components: {
    LineChart,
    ElTabs,
    ElTabPane,
    ElPageHeader,
    ProfitChart,
    ElCard,
    StrategyStatic
  },
  data() {
    return {
      title: '策略实盘表现',
      description: '利用期权等衍生工具，拥抱加密货币的高波动性',
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
              locale: zhCN,
              tooltipFormat: 'yyyy-MM-dd',
              displayFormats: {
                day: 'd'
              }
            },
            ticks: {
              callback: function(value, index, values) {
                // 获取日期
                const date = new Date(value);
                const day = date.getDate(); // 获取日期
                const month = date.getMonth(); // 获取月份
                if(index === 0){
                  return `${day}`;
                }
                const oldMonth = new Date(values[index - 1].value).getMonth(); // 确保直接访问的是日期值
                if (oldMonth !== month) {
                  // 如果是新月份，显示月份和日期
                  return `${month + 1}月`;
                } else {
                  // 否则只显示日期
                  return `${day}`;
                }
              }
            }
          }
        }
      },
      charts: [],  // 用于存储转换后的图表数据

    }
  },
  mounted() {
    this.fetchPredictPrices();
  },
  methods: {
    goBack() {
      window.history.back();
    },
    async fetchPredictPrices() {
      try {
        const response = await axios.get('https://ai.zkware.net/api/v1/advanced_strategies'); // 替换为你的 API URL
        this.charts = this.convertToCharts(response.data.data)
      } catch (error) {
        console.error('Error fetching news data:', error);
      }
    },
    convertToCharts(data) {
      const chartTemplates = [];

      Object.keys(data).forEach(symbol => {
        const symbolData = data[symbol];
        let showDays = 20
        const last14Dates = symbolData.dates.slice(-showDays).map(date => new Date(date));  // 取后 14 个日期
        const last14Amounts = symbolData.amount.slice(-showDays);  // 确保数据和日期一致
        let symbolStr = symbol
        let detail = symbolData.detail
        let showProfit = symbolData.show_profit
        let showStatic = symbolData.show_static
        let imageUrl = ""
        let imageSymbol = ""
        if (symbolStr.includes("美国大选做多波动率净值")){
          imageSymbol = "weekopt"
        }
        try {
          imageUrl = require(`@/assets/${imageSymbol}.png`);
        } catch (error) {
          // 使用默认图片路径
          imageUrl = require("@/assets/logo.png");
        }
        if (last14Dates.length !== last14Amounts.length) {
          console.warn(`Data length mismatch for symbol: ${symbolStr}`);
          return; // 数据不一致时，跳过当前 symbol 的处理
        }
        const chart = {
          title: `${symbolStr}`,
          detail: detail,
          showProfit: showProfit,
          showStatic: showStatic,
          image_url: imageUrl,
          data: {
            labels: last14Dates,
            datasets: [
              {
                label: 'USDT净值',
                data: last14Amounts,
                borderColor: '#0D83FE',
                fill: true,
                tension: 0.4
              }
            ]
          },

        };

        chartTemplates.push(chart);
      });
      chartTemplates.sort((a, b) => {
        if (a.title.includes("已结束") && !b.title.includes("已结束")) {
          return 1; // a 在 b 后面
        }
        if (!a.title.includes("已结束") && b.title.includes("已结束")) {
          return -1; // a 在 b 前面
        }
        return 0; // 不改变顺序
      });
      chartTemplates.sort((a, b) => {
        return a.showStatic - b.showStatic;
      });
      return chartTemplates;
    },
  }

}

</script>

<style scoped>
.volatility-card {
  margin: 20px;
}

.strategy-header {
  margin-bottom: 30px;
}

.strategy-header h2 {
  font-size: 24px;
  color: #303133;
  margin: 0;
}

.section {
  margin-bottom: 30px;
}

.section h3 {
  font-size: 18px;
  color: #303133;
  margin-bottom: 15px;
}

.chart-container {
  height: 300px;
  margin-top: 20px;
}

.strategy-desc {
  margin: 15px 0;
}

.dark-theme {
  background-color: #1e1e1e;
  padding: 20px;
  border-radius: 4px;
}
</style>